import React from 'react';
import { authenticatedUser } from 'header-scripts';
import { Link } from 'react-style-guide';
import { Thumbnail2d, ThumbnailTypes } from 'roblox-thumbnails';
import links from '../constants/linkConstants';
import userUtil from '../util/userUtil';

function AgeBracketDisplay() {
  const { isUnder13 } = authenticatedUser;

  return (
    <div className='age-bracket-label text-header'>
      <Link
        className='text-link dynamic-overflow-container'
        url={links.scrollListItems.profile.url}>
        <span className='avatar avatar-headshot-xs'>
          <Thumbnail2d
            containerClass='avatar-card-image'
            targetId={authenticatedUser.id}
            type={ThumbnailTypes.avatarHeadshot}
            altName={authenticatedUser.name}
          />
        </span>
        <span className='text-overflow age-bracket-label-username font-caption-header'>
          {userUtil.nameForHeader}
        </span>
      </Link>
      <span className='xsmall age-bracket-label-age text-secondary'>
        {isUnder13 ? '<13' : '13+'}
      </span>
    </div>
  );
}

AgeBracketDisplay.propTypes = {};

export default AgeBracketDisplay;
