import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link, ScrollBar } from 'react-style-guide';
import { Thumbnail2d, ThumbnailTypes } from 'roblox-thumbnails';
import { authenticatedUser } from 'header-scripts';
import links from '../constants/linkConstants';
import ScrollListContainer from '../containers/ScrollListContainer';
import userUtil from '../util/userUtil';

function LeftNavigation({ isLeftNavOpen, ...props }) {
  const classNames = ClassNames('rbx-left-col', {
    'nav-show': isLeftNavOpen
  });
  return (
    <div id='navigation' className={classNames}>
      <ul>
        <li key='username'>
          <Link
            className='dynamic-overflow-container text-nav'
            role='link'
            url={links.scrollListItems.profile.url}>
            <span className='avatar avatar-headshot-xs'>
              <Thumbnail2d
                containerClass='avatar-card-image'
                targetId={authenticatedUser.id}
                type={ThumbnailTypes.avatarHeadshot}
                altName={authenticatedUser.name}
              />
            </span>
            <div className='font-header-2 dynamic-ellipsis-item'>{userUtil.nameForDisplay}</div>
          </Link>
        </li>
        <li key='divider' className='rbx-divider' />
      </ul>
      <ScrollBar className='rbx-scrollbar'>
        <ScrollListContainer {...props} />
      </ScrollBar>
    </div>
  );
}

LeftNavigation.propTypes = {
  isLeftNavOpen: PropTypes.bool.isRequired
};

export default LeftNavigation;
